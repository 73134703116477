import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"

import ContentBlock from "../components/content-block"
import VideoBlock from "../components/video-block"
import { useOnLoadImages } from "../hooks/useOnLoadImages"
import Preloader from "../components/preloader"

const ServicesPage: React.FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "services",
        page_path: "/services",
        send_to: "G-LB7ZYCKNWC",
      })
    }
  }, [])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const imagesLoaded = useOnLoadImages(wrapperRef)
  const data = useStaticQuery(graphql`
    query {
      prismicServicesPage {
        data {
          page_title {
            text
          }
          header_text {
            richText
          }
          body {
            ... on PrismicServicesPageDataBodyContentBlock {
              slice_type
              primary {
                header {
                  text
                }
                text {
                  richText
                }
                image {
                  alt
                  url(imgixParams: { width: 1280 })
                }
                image_width
              }
            }
            ... on PrismicServicesPageDataBodyVideoBlock {
              slice_type
              primary {
                header {
                  text
                }
                text {
                  richText
                }
                video {
                  embed_url
                }
              }
            }
          }
        }
      }
    }
  `)
  const pageData = data.prismicServicesPage.data
  const slices = pageData.body

  return (
    <div ref={wrapperRef}>
      {!imagesLoaded && <Preloader />}
      <Layout>
        <div className="w-full px-10 pt-16 pb-12 font-semibold text-center text-white bg-black sm:pb-24 md:pb-32 sm:px-16 md:px-24">
          <h3 className="pb-8 text-4xl">{pageData.page_title.text}</h3>
          <div className="font-semibold leading-normal whitespace-pre-line text-xxl">
            <RichText render={pageData.header_text.richText} />
          </div>
        </div>
        {slices.map((slice, i) => {
          if (slice.slice_type === "content_block") {
            return (
              <ContentBlock
                key={i}
                header={slice.primary.header.text}
                text={slice.primary.text.richText}
                imageUrl={slice.primary.image.url}
                imageWidth={slice.primary.image_width}
                rowReversed={i % 2 == 0}
                rowsOnLgScreen
              />
            )
          } else if (slice.slice_type === "video_block") {
            return (
              <VideoBlock
                key={i}
                header={slice.primary.header.text}
                text={slice.primary.text.richText}
                video={slice.primary.video.embed_url}
                rowReversed={i % 2 == 0}
                rowsOnLgScreen
              />
            )
          } else return null
        })}
      </Layout>
    </div>
  )
}

export default ServicesPage
